import React from 'react';
import Helmet from 'react-helmet';
import Image from 'components/common/utils/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Section from '../../common/Section';
import { Layout } from '../../common';
import CharlesMeta from '../../common/meta/CharlesMeta';
import StructuredWebPage from '../../common/meta/StructuredWebPage';
import EmailForm from './EmailForm';
import './styles.sass';
import AboutUs from '../AboutUs';
import PressBanner from '../../Press/PressBanner';
import { Link } from 'gatsby';

class AcademyTeaser extends React.Component {
  render() {
    const { success } = this.props;
    return (
      <Layout
        showLinksInFooter={false}
        showSpecialtiesInFooter={false}
      >
        <CharlesMeta
          data={{
            title: 'Episode gratuit thérapie digitale | Charles.co'
          }}
        />
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="academy-landing-page">
          <Section className="email-success-section">
            <Container className="mt-2 mb-2">
              <Row className="mb-5 mt-5 text-center">
                <Col>
                  <h1 className="h2">Je reçois un épisode gratuitement</h1>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col lg={{ span: 8, offset: 2 }} className="text-center">
                  {success ? (
                    <div className="text-center">
                      Un email vous a été envoyé pour accéder à votre épisode gratuit.
                      <Image
                        className="img-fluid free-episode-confirmation my-5"
                        filename="academy/academy-confirmation.svg"
                        alt=""
                      />
                    </div>
                  ) : (
                    <EmailForm />
                  )}
                </Col>
              </Row>
            </Container>
          </Section>
        </div>
      </Layout>
    );
  }
}

export default AcademyTeaser;
